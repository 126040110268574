<template>
  <div style="color: #666;font-size: 14px;">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="车间工单统计数据" name="1">
        <el-row :gutter="10" style="margin-bottom: 60px">
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀一线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yixianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yixianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀二线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{erxianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{erxianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀三线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{sanxianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{sanxianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 铸造</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zhuzaoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zhuzaoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 压铸</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yazhuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yazhuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 机加</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{jijiaSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{jijiaHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 工模</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{gongmoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{gongmoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 研磨</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yanmoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yanmoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 涂装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{tuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{tuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> QA品保</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{QApinbaoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{QApinbaoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 生活污水</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{shenghuoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{shenghuoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> PVD</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{pvdSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{pvdHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 注塑</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zhusuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zhusuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 设施维修</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{sheshiSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{sheshiHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 立库</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{likuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{likuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 花洒组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{huasaSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{huasaHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> QA组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{QAzuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{QAzuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 行政楼</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{xingzhengSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{xingzhengHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 污水处理</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{wushuiSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{wushuiHour}}h
              </div>
            </el-card>
          </el-col>
        </el-row>
        <!--车间数据 饼图区域-->
        <div class="chart-container">
          <div ref="chart" class="chart"></div>
          <div ref="chart2" class="chart"></div>
        </div>

        <!--时间选择器-->
        <div class="block">
          <el-date-picker
              v-model="start"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择起始日期"
          ></el-date-picker>
          <el-date-picker
              v-model="end"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择截止日期"
          ></el-date-picker>
          <el-button class="ml-5" type="primary" @click="load2">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>


        <div ref="chatRecord" class="chat3" id="chatRecord"></div>
        <div ref="chatHour" class="chat3" id="chatHour"></div>



        <!--工时工单图区域-->
        <!--        <div ref="chart3" class="chart2"></div>-->
        <!--        <div ref="chart4" class="chart2"></div>-->
        <!--        <div ref="chart5" class="chart2"></div>-->
        <!--        <div ref="chart6" class="chart2"></div>-->
        <!--        <div ref="chart37" class="chart2"></div>-->
        <!--        <div ref="chart38" class="chart2"></div>-->
        <!--        <div ref="chart39" class="chart2"></div>-->
        <!--        <div ref="chart40" class="chart2"></div>-->
        <!--        <div ref="chart41" class="chart2"></div>-->
        <!--        <div ref="chart42" class="chart2"></div>-->
        <!--        <div ref="chart7" class="chart2"></div>-->
        <!--        <div ref="chart8" class="chart2"></div>-->
        <!--        <div ref="chart9" class="chart2"></div>-->
        <!--        <div ref="chart10" class="chart2"></div>-->
        <!--        <div ref="chart11" class="chart2"></div>-->
        <!--        <div ref="chart12" class="chart2"></div>-->
        <!--        <div ref="chart13" class="chart2"></div>-->
        <!--        <div ref="chart14" class="chart2"></div>-->
        <!--        <div ref="chart15" class="chart2"></div>-->
        <!--        <div ref="chart16" class="chart2"></div>-->
        <!--        <div ref="chart17" class="chart2"></div>-->
        <!--        <div ref="chart18" class="chart2"></div>-->
        <!--        <div ref="chart19" class="chart2"></div>-->
        <!--        <div ref="chart20" class="chart2"></div>-->
        <!--        <div ref="chart21" class="chart2"></div>-->
        <!--        <div ref="chart22" class="chart2"></div>-->
        <!--        <div ref="chart23" class="chart2"></div>-->
        <!--        <div ref="chart24" class="chart2"></div>-->
        <!--        <div ref="chart25" class="chart2"></div>-->
        <!--        <div ref="chart26" class="chart2"></div>-->
        <!--        <div ref="chart27" class="chart2"></div>-->
        <!--        <div ref="chart28" class="chart2"></div>-->
        <!--        <div ref="chart29" class="chart2"></div>-->
        <!--        <div ref="chart30" class="chart2"></div>-->
        <!--        <div ref="chart31" class="chart2"></div>-->
        <!--        <div ref="chart32" class="chart2"></div>-->
        <!--        <div ref="chart33" class="chart2"></div>-->
        <!--        <div ref="chart34" class="chart2"></div>-->
        <!--        <div ref="chart35" class="chart2"></div>-->
        <!--        <div ref="chart36" class="chart2"></div>-->



      </el-collapse-item>

      <el-collapse-item title="车间数据" name="2">
        <el-card v-for="item in lines" :key="item.id" style="margin-bottom: 20px;">
          <div >
            <span style="font-weight: bold;font-size: 20px">{{ item.name }}  |</span>
            <span style="margin-left: 50px;font-size: 18px">总工单数:{{ item.recordnum }}</span>
            <span style="margin-left: 50px;font-size: 18px">总工时:{{ item.hour }}h</span>
          </div>

          <el-divider></el-divider>
          <div style="font-size: 18px">
            <span style="margin-left: 50px">车间报修工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">车间报修工时:{{ item.hour }}h</span>
            <span style="margin-left: 50px">维修自查工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">维修自查工时:{{ item.hour }}h</span>

          </div>

          <div style="font-size: 18px">
            <span style="margin-left: 50px">管理巡审工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">管理巡审工时:{{ item.hour }}h</span>
            <span style="margin-left: 50px">获批技改工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">获批技改工时:{{ item.hour }}h</span>
          </div>


        </el-card>
      </el-collapse-item>

    </el-collapse>

  </div>
</template>

<script>

import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import {ToolboxComponent,} from 'echarts/components';

echarts.use([PieChart, ToolboxComponent,TooltipComponent, LegendComponent, TitleComponent, CanvasRenderer,GridComponent, BarChart]);


export default {
  name: "LineChart",
  data() {
    return {
      form: {},
      day: '',
      start: "",//开始时间
      end:"",//截止时间
      allSum: 0,
      allPirce: 0,


      // 电镀一线
      yixianchejianbaoxiuSum:0,
      yixianchejianbaoxiuHour:0,
      yixianweixiuzichaSum:0,
      yixianweixiuzichaHour:0,
      yixianGuanlixunshenSum:0,
      yixianGuanlixunshenHour:0,
      yixianHuopijigaiSum:0,
      yixianHuopijigaiHour:0,

      // 电镀二线
      erxianchejianbaoxiuSum:0,
      erxianchejianbaoxiuHour:0,
      erxianweixiuzichaSum:0,
      erxianweixiuzichaHour:0,
      erxianGuanlixunshenSum:0,
      erxianGuanlixunshenHour:0,
      erxianHuopijigaiSum:0,
      erxianHuopijigaiHour:0,

      // 电镀三线
      sanxianchejianbaoxiuSum:0,
      sanxianchejianbaoxiuHour:0,
      sanxianweixiuzichaSum:0,
      sanxianweixiuzichaHour:0,
      sanxianGuanlixunshenSum:0,
      sanxianGuanlixunshenHour:0,
      sanxianHuopijigaiSum:0,
      sanxianHuopijigaiHour:0,

      // 铸造
      zhuzaochejianbaoxiuSum:0,
      zhuzaochejianbaoxiuHour:0,
      zhuzaoweixiuzichaSum:0,
      zhuzaoweixiuzichaHour:0,
      zhuzaoGuanlixunshenSum:0,
      zhuzaoGuanlixunshenHour:0,
      zhuzaoHuopijigaiSum:0,
      zhuzaoHuopijigaiHour:0,

      // 压铸
      yazhuchejianbaoxiuSum:0,
      yazhuchejianbaoxiuHour:0,
      yazhuweixiuzichaSum:0,
      yazhuweixiuzichaHour:0,
      yazhuGuanlixunshenSum:0,
      yazhuGuanlixunshenHour:0,
      yazhuHuopijigaiSum:0,
      yazhuHuopijigaiHour:0,

      // 机加
      jijiachejianbaoxiuSum:0,
      jijiachejianbaoxiuHour:0,
      jijiaweixiuzichaSum:0,
      jijiaweixiuzichaHour:0,
      jijiaGuanlixunshenSum:0,
      jijiaGuanlixunshenHour:0,
      jijiaHuopijigaiSum:0,
      jijiaHuopijigaiHour:0,

      // 工模
      gongmochejianbaoxiuSum:0,
      gongmochejianbaoxiuHour:0,
      gongmoweixiuzichaSum:0,
      gongmoweixiuzichaHour:0,
      gongmoGuanlixunshenSum:0,
      gongmoGuanlixunshenHour:0,
      gongmoHuopijigaiSum:0,
      gongmoHuopijigaiHour:0,

      // 研磨
      yanmochejianbaoxiuSum:0,
      yanmochejianbaoxiuHour:0,
      yanmoweixiuzichaSum:0,
      yanmoweixiuzichaHour:0,
      yanmoGuanlixunshenSum:0,
      yanmoGuanlixunshenHour:0,
      yanmoHuopijigaiSum:0,
      yanmoHuopijigaiHour:0,

      // 涂装
      tuzhuangchejianbaoxiuSum:0,
      tuzhuangchejianbaoxiuHour:0,
      tuzhuangweixiuzichaSum:0,
      tuzhuangweixiuzichaHour:0,
      tuzhuangGuanlixunshenSum:0,
      tuzhuangGuanlixunshenHour:0,
      tuzhuangHuopijigaiSum:0,
      tuzhuangHuopijigaiHour:0,

      // QA品保
      QAPinbaoChejianbaoxiuSum:0,
      QAPinbaoChejianbaoxiuHour:0,
      QAPinbaoWeixiuzichaSum:0,
      QAPinbaoWeixiuzichaHour:0,
      QAPinbaoGuanlixunshenSum:0,
      QAPinbaoGuanlixunshenHour:0,
      QAPinbaoHuopijigaiSum:0,
      QAPinbaoHuopijigaiHour:0,

      // 生活污水
      shenghuowushuiChejianbaoxiuSum:0,
      shenghuowushuiChejianbaoxiuHour:0,
      shenghuowushuiWeixiuzichaSum:0,
      shenghuowushuiWeixiuzichaHour:0,
      shenghuowushuiGuanlixunshenSum:0,
      shenghuowushuiGuanlixunshenHour:0,
      shenghuowushuiHuopijigaiSum:0,
      shenghuowushuiHuopijigaiHour:0,

      // PVD
      PVDchejianbaoxiuSum:0,
      PVDchejianbaoxiuHour:0,
      PVDweixiuzichaSum:0,
      PVDweixiuzichaHour:0,
      PVDGuanlixunshenSum:0,
      PVDGuanlixunshenHour:0,
      PVDHuopijigaiSum:0,
      PVDHuopijigaiHour:0,

      // 注塑
      zhusuchejianbaoxiuSum:0,
      zhusuchejianbaoxiuHour:0,
      zhusuweixiuzichaSum:0,
      zhusuweixiuzichaHour:0,
      zhusuGuanlixunshenSum:0,
      zhusuGuanlixunshenHour:0,
      zhusuHuopijigaiSum:0,
      zhusuHuopijigaiHour:0,

      // 设施维修
      sheshichejianbaoxiuSum:0,
      sheshichejianbaoxiuHour:0,
      sheshiweixiuzichaSum:0,
      sheshiweixiuzichaHour:0,
      sheshiGuanlixunshenSum:0,
      sheshiGuanlixunshenHour:0,
      sheshiHuopijigaiSum:0,
      sheshiHuopijigaiHour:0,

      // 立库
      likuChejianbaoxiuSum:0,
      likuChejianbaoxiuHour:0,
      likuWeixiuzichaSum:0,
      likuWeixiuzichaHour:0,
      likuGuanlixunshenSum:0,
      likuGuanlixunshenHour:0,
      likuHuopijigaiSum:0,
      likuHuopijigaiHour:0,

      // 组装
      zuzhuangchejianbaoxiuSum:0,
      zuzhuangchejianbaoxiuHour:0,
      zuzhuangweixiuzichaSum:0,
      zuzhuangweixiuzichaHour:0,
      zuzhuangGuanlixunshenSum:0,
      zuzhuangGuanlixunshenHour:0,
      zuzhuangHuopijigaiSum:0,
      zuzhuangHuopijigaiHour:0,

      // 花洒组装
      huasaChejianbaoxiuSum:0,
      huasaChejianbaoxiuHour:0,
      huasaWeixiuzichaSum:0,
      huasaWeixiuzichaHour:0,
      huasaGuanlixunshenSum:0,
      huasaGuanlixunshenHour:0,
      huasaHuopijigaiSum:0,
      huasaHuopijigaiHour:0,

      // QA组
      QAZuzhuangChejianbaoxiuSum:0,
      QAZuzhuangChejianbaoxiuHour:0,
      QAZuzhuangWeixiuzichaSum:0,
      QAZuzhuangWeixiuzichaHour:0,
      QAZuzhuangGuanlixunshenSum:0,
      QAZuzhuangGuanlixunshenHour:0,
      QAZuzhuangHuopijigaiSum:0,
      QAZuzhuangHuopijigaiHour:0,

      // 行政,
      xingzhenglouChejianbaoxiuSum:0,
      xingzhenglouChejianbaoxiuHour:0,
      xingzhenglouWeixiuzichaSum:0,
      xingzhenglouWeixiuzichaHour:0,
      xingzhenglouGuanlixunshenSum:0,
      xingzhenglouGuanlixunshenHour:0,
      xingzhenglouHuopijigaiSum:0,
      xingzhenglouHuopijigaiHour:0,

      // 污水处理
      wushuilouChejianbaoxiuSum:0,
      wushuilouChejianbaoxiuHou:0,
      wushuilouWeixiuzichaSum:0,
      wushuilouWeixiuzichaHour:0,
      wushuilouGuanlixunshenSum:0,
      wushuilouGuanlixunshenHou:0,
      wushuilouHuopijigaiSum:0,
      wushuilouHuopijigaiHour:0,

      yixianSumByDate: 0,
      erxianSumByDate: 0,
      sanxianSumByDate: 0,
      zhuzaoSumByDate: 0,
      yazhuSumByDate: 0,
      jijiaSumByDate: 0,
      gongmoSumByDate: 0,
      yanmoSumByDate: 0,
      tuzhuangSumByDate: 0,
      QApinbaoSumByDate: 0,
      shenghuoSumByDate: 0,
      pvdSumByDate: 0,
      zhusuSumByDate: 0,
      sheshiSumByDate: 0,
      likuSumByDate: 0,
      zuzhuangSumByDate: 0,
      huasaSumByDate: 0,
      QAzuzhuangSumByDate: 0,
      xingzhengSumByDate: 0,
      wushuiSumByDate: 0,

      yixianHourByDate: 0,
      erxianHourByDate: 0,
      sanxianHourByDate: 0,
      zhuzaoHourByDate: 0,
      yazhuHourByDate: 0,
      jijiaHourByDate: 0,
      gongmoHourByDate: 0,
      yanmoHourByDate: 0,
      tuzhuangHourByDate: 0,
      QApinbaoHourByDate: 0,
      shenghuoHourByDate: 0,
      pvdHourByDate: 0,
      zhusuHourByDate: 0,
      sheshiHourByDate: 0,
      likuHourByDate: 0,
      zuzhuangHourByDate: 0,
      huasaHourByDate: 0,
      QAzuzhuangHourByDate: 0,
      xingzhengHourByDate: 0,
      wushuiHourByDate: 0,

      /*上部分是时间选择器*/
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      users: [],
      groups: [],
      morningShift: [],
      afternoonShift: [],
      eveningShift: [],
      maintainers: [],
      failureRecords: [],
      lines: [],
      usernum: 0,
      mtnum: 0,
      recordnum: 0,
      weekRecordnum: 0,
      EmergencyRecord: 0,
      PreventiveRecord: 0,
      reportsum: 0,
      activeNames: ['1','2'],
      yixianSum: 0,
      erxianSum: 0,
      sanxianSum: 0,
      zhuzaoSum: 0,
      yazhuSum: 0,
      jijiaSum: 0,
      gongmoSum: 0,
      yanmoSum: 0,
      tuzhuangSum: 0,
      QApinbaoSum: 0,
      shenghuoSum: 0,
      pvdSum: 0,
      zhusuSum: 0,
      sheshiSum: 0,
      likuSum: 0,
      zuzhuangSum: 0,
      huasaSum: 0,
      QAzuzhuangSum: 0,
      xingzhengSum: 0,
      wushuiSum: 0,

      yixianHour: 0,
      erxianHour: 0,
      sanxianHour: 0,
      zhuzaoHour: 0,
      yazhuHour: 0,
      jijiaHour: 0,
      gongmoHour: 0,
      yanmoHour: 0,
      tuzhuangHour: 0,
      QApinbaoHour: 0,
      shenghuoHour: 0,
      pvdHour: 0,
      zhusuHour: 0,
      sheshiHour: 0,
      likuHour: 0,
      zuzhuangHour: 0,
      huasaHour: 0,
      QAzuzhuangHour: 0,
      xingzhengHour: 0,
      wushuiHour: 0
    }
  },
  /*
  * 创建时运行load方法，想后端请求数据
  * */
  created() {
    this.load()
  },
  mounted() {
    this.initChart();
  },
  methods: {
    reset(){
      this.start = ""
      this.end = ""
      this.load2()
    },
    load2() {
      //论时间
      this.request.get('/failureRecord/getAll',{
        params: {
          start: this.start,
          end: this.end
        }
      },{timeout:10000}).then(res=>{
        /**
         * 汇总各车间的总工单和总工时
         * @type {number|any}
         */

        this.yixianchejianbaoxiuSum = res.data.yixianchejianbaoxiuSum ? res.data.yixianchejianbaoxiuSum : 0;
        this.yixianchejianbaoxiuHour = res.data.yixianchejianbaoxiuHour ? res.data.yixianchejianbaoxiuHour : 0;
        this.yixianweixiuzichaSum = res.data.yixianweixiuzichaSum ? res.data.yixianweixiuzichaSum : 0;
        this.yixianweixiuzichaHour = res.data.yixianweixiuzichaHour ? res.data.yixianweixiuzichaHour : 0;
        this.yixianGuanlixunshenSum = res.data.yixianGuanlixunshenSum ? res.data.yixianGuanlixunshenSum : 0;
        this.yixianGuanlixunshenHour = res.data.yixianGuanlixunshenHour ? res.data.yixianGuanlixunshenHour : 0;
        this.yixianHuopijigaiSum = res.data.yixianHuopijigaiSum ? res.data.yixianHuopijigaiSum : 0;
        this.yixianHuopijigaiHour = res.data.yixianHuopijigaiHour ? res.data.yixianHuopijigaiHour : 0;

        this.erxianchejianbaoxiuSum = res.data.erxianchejianbaoxiuSum ? res.data.erxianchejianbaoxiuSum : 0;
        this.erxianchejianbaoxiuHour = res.data.erxianchejianbaoxiuHour ? res.data.erxianchejianbaoxiuHour : 0;
        this.erxianweixiuzichaSum = res.data.erxianweixiuzichaSum ? res.data.erxianweixiuzichaSum : 0;
        this.erxianweixiuzichaHour = res.data.erxianweixiuzichaHour ? res.data.erxianweixiuzichaHour : 0;
        this.erxianGuanlixunshenSum = res.data.erxianGuanlixunshenSum ? res.data.erxianGuanlixunshenSum : 0;
        this.erxianGuanlixunshenHour = res.data.erxianGuanlixunshenHour ? res.data.erxianGuanlixunshenHour : 0;
        this.erxianHuopijigaiSum = res.data.erxianHuopijigaiSum ? res.data.erxianHuopijigaiSum : 0;
        this.erxianHuopijigaiHour = res.data.erxianHuopijigaiHour ? res.data.erxianHuopijigaiHour : 0;

        this.sanxianchejianbaoxiuSum = res.data.sanxianchejianbaoxiuSum ? res.data.sanxianchejianbaoxiuSum : 0;
        this.sanxianchejianbaoxiuHour = res.data.sanxianchejianbaoxiuHour ? res.data.sanxianchejianbaoxiuHour : 0;
        this.sanxianweixiuzichaSum = res.data.sanxianweixiuzichaSum ? res.data.sanxianweixiuzichaSum : 0;
        this.sanxianweixiuzichaHour = res.data.sanxianweixiuzichaHour ? res.data.sanxianweixiuzichaHour : 0;
        this.sanxianGuanlixunshenSum = res.data.sanxianGuanlixunshenSum ? res.data.sanxianGuanlixunshenSum : 0;
        this.sanxianGuanlixunshenHour = res.data.sanxianGuanlixunshenHour ? res.data.sanxianGuanlixunshenHour : 0;
        this.sanxianHuopijigaiSum = res.data.sanxianHuopijigaiSum ? res.data.sanxianHuopijigaiSum : 0;
        this.sanxianHuopijigaiHour = res.data.sanxianHuopijigaiHour ? res.data.sanxianHuopijigaiHour : 0;

        this.zhuzaochejianbaoxiuSum = res.data.zhuzaochejianbaoxiuSum ? res.data.zhuzaochejianbaoxiuSum : 0;
        this.zhuzaochejianbaoxiuHour = res.data.zhuzaochejianbaoxiuHour ? res.data.zhuzaochejianbaoxiuHour : 0;
        this.zhuzaoweixiuzichaSum = res.data.zhuzaoweixiuzichaSum ? res.data.zhuzaoweixiuzichaSum : 0;
        this.zhuzaoweixiuzichaHour = res.data.zhuzaoweixiuzichaHour ? res.data.zhuzaoweixiuzichaHour : 0;
        this.zhuzaoGuanlixunshenSum = res.data.zhuzaoGuanlixunshenSum ? res.data.zhuzaoGuanlixunshenSum : 0;
        this.zhuzaoGuanlixunshenHour = res.data.zhuzaoGuanlixunshenHour ? res.data.zhuzaoGuanlixunshenHour : 0;
        this.zhuzaoHuopijigaiSum = res.data.zhuzaoHuopijigaiSum ? res.data.zhuzaoHuopijigaiSum : 0;
        this.zhuzaoHuopijigaiHour = res.data.zhuzaoHuopijigaiHour ? res.data.zhuzaoHuopijigaiHour : 0;

        this.yazhuchejianbaoxiuSum = res.data.yazhuchejianbaoxiuSum ? res.data.yazhuchejianbaoxiuSum : 0;
        this.yazhuchejianbaoxiuHour = res.data.yazhuchejianbaoxiuHour ? res.data.yazhuchejianbaoxiuHour : 0;
        this.yazhuweixiuzichaSum = res.data.yazhuweixiuzichaSum ? res.data.yazhuweixiuzichaSum : 0;
        this.yazhuweixiuzichaHour = res.data.yazhuweixiuzichaHour ? res.data.yazhuweixiuzichaHour : 0;
        this.yazhuGuanlixunshenSum = res.data.yazhuGuanlixunshenSum ? res.data.yazhuGuanlixunshenSum : 0;
        this.yazhuGuanlixunshenHour = res.data.yazhuGuanlixunshenHour ? res.data.yazhuGuanlixunshenHour : 0;
        this.yazhuHuopijigaiSum = res.data.yazhuHuopijigaiSum ? res.data.yazhuHuopijigaiSum : 0;
        this.yazhuHuopijigaiHour = res.data.yazhuHuopijigaiHour ? res.data.yazhuHuopijigaiHour : 0;

        this.jijiachejianbaoxiuSum = res.data.jijiachejianbaoxiuSum ? res.data.jijiachejianbaoxiuSum : 0;
        this.jijiachejianbaoxiuHour = res.data.jijiachejianbaoxiuHour ? res.data.jijiachejianbaoxiuHour : 0;
        this.jijiaweixiuzichaSum = res.data.jijiaweixiuzichaSum ? res.data.jijiaweixiuzichaSum : 0;
        this.jijiaweixiuzichaHour = res.data.jijiaweixiuzichaHour ? res.data.jijiaweixiuzichaHour : 0;
        this.jijiaGuanlixunshenSum = res.data.jijiaGuanlixunshenSum ? res.data.jijiaGuanlixunshenSum : 0;
        this.jijiaGuanlixunshenHour = res.data.jijiaGuanlixunshenHour ? res.data.jijiaGuanlixunshenHour : 0;
        this.jijiaHuopijigaiSum = res.data.jijiaHuopijigaiSum ? res.data.jijiaHuopijigaiSum : 0;
        this.jijiaHuopijigaiHour = res.data.jijiaHuopijigaiHour ? res.data.jijiaHuopijigaiHour : 0;

        // 工模
        this.gongmochejianbaoxiuSum = res.data.gongmochejianbaoxiuSum ? res.data.gongmochejianbaoxiuSum : 0;
        this.gongmochejianbaoxiuHour = res.data.gongmochejianbaoxiuHour ? res.data.gongmochejianbaoxiuHour : 0;
        this.gongmoweixiuzichaSum = res.data.gongmoweixiuzichaSum ? res.data.gongmoweixiuzichaSum : 0;
        this.gongmoweixiuzichaHour = res.data.gongmoweixiuzichaHour ? res.data.gongmoweixiuzichaHour : 0;
        this.gongmoGuanlixunshenSum = res.data.gongmoGuanlixunshenSum ? res.data.gongmoGuanlixunshenSum : 0;
        this.gongmoGuanlixunshenHour = res.data.gongmoGuanlixunshenHour ? res.data.gongmoGuanlixunshenHour : 0;
        this.gongmoHuopijigaiSum = res.data.gongmoHuopijigaiSum ? res.data.gongmoHuopijigaiSum : 0;
        this.gongmoHuopijigaiHour = res.data.gongmoHuopijigaiHour ? res.data.gongmoHuopijigaiHour : 0;

        // 研磨
        this.yanmochejianbaoxiuSum = res.data.yanmochejianbaoxiuSum ? res.data.yanmochejianbaoxiuSum : 0;
        this.yanmochejianbaoxiuHour = res.data.yanmochejianbaoxiuHour ? res.data.yanmochejianbaoxiuHour : 0;
        this.yanmoweixiuzichaSum = res.data.yanmoweixiuzichaSum ? res.data.yanmoweixiuzichaSum : 0;
        this.yanmoweixiuzichaHour = res.data.yanmoweixiuzichaHour ? res.data.yanmoweixiuzichaHour : 0;
        this.yanmoGuanlixunshenSum = res.data.yanmoGuanlixunshenSum ? res.data.yanmoGuanlixunshenSum : 0;
        this.yanmoGuanlixunshenHour = res.data.yanmoGuanlixunshenHour ? res.data.yanmoGuanlixunshenHour : 0;
        this.yanmoHuopijigaiSum = res.data.yanmoHuopijigaiSum ? res.data.yanmoHuopijigaiSum : 0;
        this.yanmoHuopijigaiHour = res.data.yanmoHuopijigaiHour ? res.data.yanmoHuopijigaiHour : 0;

        // 涂装
        this.tuzhuangchejianbaoxiuSum = res.data.tuzhuangchejianbaoxiuSum ? res.data.tuzhuangchejianbaoxiuSum : 0;
        this.tuzhuangchejianbaoxiuHour = res.data.tuzhuangchejianbaoxiuHour ? res.data.tuzhuangchejianbaoxiuHour : 0;
        this.tuzhuangweixiuzichaSum = res.data.tuzhuangweixiuzichaSum ? res.data.tuzhuangweixiuzichaSum : 0;
        this.tuzhuangweixiuzichaHour = res.data.tuzhuangweixiuzichaHour ? res.data.tuzhuangweixiuzichaHour : 0;
        this.tuzhuangGuanlixunshenSum = res.data.tuzhuangGuanlixunshenSum ? res.data.tuzhuangGuanlixunshenSum : 0;
        this.tuzhuangGuanlixunshenHour = res.data.tuzhuangGuanlixunshenHour ? res.data.tuzhuangGuanlixunshenHour : 0;
        this.tuzhuangHuopijigaiSum = res.data.tuzhuangHuopijigaiSum ? res.data.tuzhuangHuopijigaiSum : 0;
        this.tuzhuangHuopijigaiHour = res.data.tuzhuangHuopijigaiHour ? res.data.tuzhuangHuopijigaiHour : 0;

        // QA品保
        this.QAPinbaoChejianbaoxiuSum = res.data.QAPinbaoChejianbaoxiuSum ? res.data.QAPinbaoChejianbaoxiuSum : 0;
        this.QAPinbaoChejianbaoxiuHour = res.data.QAPinbaoChejianbaoxiuHour ? res.data.QAPinbaoChejianbaoxiuHour : 0;
        this.QAPinbaoWeixiuzichaSum = res.data.QAPinbaoWeixiuzichaSum ? res.data.QAPinbaoWeixiuzichaSum : 0;
        this.QAPinbaoWeixiuzichaHour = res.data.QAPinbaoWeixiuzichaHour ? res.data.QAPinbaoWeixiuzichaHour : 0;
        this.QAPinbaoGuanlixunshenSum = res.data.QAPinbaoGuanlixunshenSum ? res.data.QAPinbaoGuanlixunshenSum : 0;
        this.QAPinbaoGuanlixunshenHour = res.data.QAPinbaoGuanlixunshenHour ? res.data.QAPinbaoGuanlixunshenHour : 0;
        this.QAPinbaoHuopijigaiSum = res.data.QAPinbaoHuopijigaiSum ? res.data.QAPinbaoHuopijigaiSum : 0;
        this.QAPinbaoHuopijigaiHour = res.data.QAPinbaoHuopijigaiHour ? res.data.QAPinbaoHuopijigaiHour : 0;

        // 生活污水
        this.shenghuowushuiChejianbaoxiuSum = res.data.shenghuowushuiChejianbaoxiuSum ? res.data.shenghuowushuiChejianbaoxiuSum : 0;
        this.shenghuowushuiChejianbaoxiuHour = res.data.shenghuowushuiChejianbaoxiuHour ? res.data.shenghuowushuiChejianbaoxiuHour : 0;
        this.shenghuowushuiWeixiuzichaSum = res.data.shenghuowushuiWeixiuzichaSum ? res.data.shenghuowushuiWeixiuzichaSum : 0;
        this.shenghuowushuiWeixiuzichaHour = res.data.shenghuowushuiWeixiuzichaHour ? res.data.shenghuowushuiWeixiuzichaHour : 0;
        this.shenghuowushuiGuanlixunshenSum = res.data.shenghuowushuiGuanlixunshenSum ? res.data.shenghuowushuiGuanlixunshenSum : 0;
        this.shenghuowushuiGuanlixunshenHour = res.data.shenghuowushuiGuanlixunshenHour ? res.data.shenghuowushuiGuanlixunshenHour : 0;
        this.shenghuowushuiHuopijigaiSum = res.data.shenghuowushuiHuopijigaiSum ? res.data.shenghuowushuiHuopijigaiSum : 0;
        this.shenghuowushuiHuopijigaiHour = res.data.shenghuowushuiHuopijigaiHour ? res.data.shenghuowushuiHuopijigaiHour : 0;

        // PVD
        this.PVDchejianbaoxiuSum = res.data.PVDchejianbaoxiuSum ? res.data.PVDchejianbaoxiuSum : 0;
        this.PVDchejianbaoxiuHour = res.data.PVDchejianbaoxiuHour ? res.data.PVDchejianbaoxiuHour : 0;
        this.PVDweixiuzichaSum = res.data.PVDweixiuzichaSum ? res.data.PVDweixiuzichaSum : 0;
        this.PVDweixiuzichaHour = res.data.PVDweixiuzichaHour ? res.data.PVDweixiuzichaHour : 0;
        this.PVDGuanlixunshenSum = res.data.PVDGuanlixunshenSum ? res.data.PVDGuanlixunshenSum : 0;
        this.PVDGuanlixunshenHour = res.data.PVDGuanlixunshenHour ? res.data.PVDGuanlixunshenHour : 0;
        this.PVDHuopijigaiSum = res.data.PVDHuopijigaiSum ? res.data.PVDHuopijigaiSum : 0;
        this.PVDHuopijigaiHour = res.data.PVDHuopijigaiHour ? res.data.PVDHuopijigaiHour : 0;

        // 注塑
        this.zhusuchejianbaoxiuSum = res.data.zhusuchejianbaoxiuSum ? res.data.zhusuchejianbaoxiuSum : 0;
        this.zhusuchejianbaoxiuHour = res.data.zhusuchejianbaoxiuHour ? res.data.zhusuchejianbaoxiuHour : 0;
        this.zhusuweixiuzichaSum = res.data.zhusuweixiuzichaSum ? res.data.zhusuweixiuzichaSum : 0;
        this.zhusuweixiuzichaHour = res.data.zhusuweixiuzichaHour ? res.data.zhusuweixiuzichaHour : 0;
        this.zhusuGuanlixunshenSum = res.data.zhusuGuanlixunshenSum ? res.data.zhusuGuanlixunshenSum : 0;
        this.zhusuGuanlixunshenHour = res.data.zhusuGuanlixunshenHour ? res.data.zhusuGuanlixunshenHour : 0;
        this.zhusuHuopijigaiSum = res.data.zhusuHuopijigaiSum ? res.data.zhusuHuopijigaiSum : 0;
        this.zhusuHuopijigaiHour = res.data.zhusuHuopijigaiHour ? res.data.zhusuHuopijigaiHour : 0;

        // 设施维修
        this.shebeiwxChejianbaoxiuSum = res.data.shebeiwxChejianbaoxiuSum ? res.data.shebeiwxChejianbaoxiuSum : 0;
        this.shebeiwxChejianbaoxiuHour = res.data.shebeiwxChejianbaoxiuHour ? res.data.shebeiwxChejianbaoxiuHour : 0;
        this.shebeiwxWeixiuzichaSum = res.data.shebeiwxWeixiuzichaSum ? res.data.shebeiwxWeixiuzichaSum : 0;
        this.shebeiwxWeixiuzichaHour = res.data.shebeiwxWeixiuzichaHour ? res.data.shebeiwxWeixiuzichaHour : 0;
        this.shebeiwxGuanlixunshenSum = res.data.shebeiwxGuanlixunshenSum ? res.data.shebeiwxGuanlixunshenSum : 0;
        this.shebeiwxGuanlixunshenHour = res.data.shebeiwxGuanlixunshenHour ? res.data.shebeiwxGuanlixunshenHour : 0;
        this.shebeiwxHuopijigaiSum = res.data.shebeiwxHuopijigaiSum ? res.data.shebeiwxHuopijigaiSum : 0;
        this.shebeiwxHuopijigaiHour = res.data.shebeiwxHuopijigaiHour ? res.data.shebeiwxHuopijigaiHour : 0;

        // 立库
        this.likuChejianbaoxiuSum = res.data.likuChejianbaoxiuSum ? res.data.likuChejianbaoxiuSum : 0;
        this.likuChejianbaoxiuHour = res.data.likuChejianbaoxiuHour ? res.data.likuChejianbaoxiuHour : 0;
        this.likuWeixiuzichaSum = res.data.likuWeixiuzichaSum ? res.data.likuWeixiuzichaSum : 0;
        this.likuWeixiuzichaHour = res.data.likuWeixiuzichaHour ? res.data.likuWeixiuzichaHour : 0;
        this.likuGuanlixunshenSum = res.data.likuGuanlixunshenSum ? res.data.likuGuanlixunshenSum : 0;
        this.likuGuanlixunshenHour = res.data.likuGuanlixunshenHour ? res.data.likuGuanlixunshenHour : 0;
        this.likuHuopijigaiSum = res.data.likuHuopijigaiSum ? res.data.likuHuopijigaiSum : 0;
        this.likuHuopijigaiHour = res.data.likuHuopijigaiHour ? res.data.likuHuopijigaiHour : 0;

        // 组装
        this.zuzhuangChejianbaoxiuSum = res.data.zuzhuangChejianbaoxiuSum ? res.data.zuzhuangChejianbaoxiuSum : 0;
        this.zuzhuangChejianbaoxiuHour = res.data.zuzhuangChejianbaoxiuHour ? res.data.zuzhuangChejianbaoxiuHour : 0;
        this.zuzhuangWeixiuzichaSum = res.data.zuzhuangWeixiuzichaSum ? res.data.zuzhuangWeixiuzichaSum : 0;
        this.zuzhuangWeixiuzichaHour = res.data.zuzhuangWeixiuzichaHour ? res.data.zuzhuangWeixiuzichaHour : 0;
        this.zuzhuangGuanlixunshenSum = res.data.zuzhuangGuanlixunshenSum ? res.data.zuzhuangGuanlixunshenSum : 0;
        this.zuzhuangGuanlixunshenHour = res.data.zuzhuangGuanlixunshenHour ? res.data.zuzhuangGuanlixunshenHour : 0;
        this.zuzhuangHuopijigaiSum = res.data.zuzhuangHuopijigaiSum ? res.data.zuzhuangHuopijigaiSum : 0;
        this.zuzhuangHuopijigaiHour = res.data.zuzhuangHuopijigaiHour ? res.data.zuzhuangHuopijigaiHour : 0;

        // 花洒组装
        this.huasazuzhuangChejianbaoxiuSum = res.data.huasazuzhuangChejianbaoxiuSum ? res.data.huasazuzhuangChejianbaoxiuSum : 0;
        this.huasazuzhuangChejianbaoxiuHour = res.data.huasazuzhuangChejianbaoxiuHour ? res.data.huasazuzhuangChejianbaoxiuHour : 0;
        this.huasazuzhuangWeixiuzichaSum = res.data.huasazuzhuangWeixiuzichaSum ? res.data.huasazuzhuangWeixiuzichaSum : 0;
        this.huasazuzhuangWeixiuzichaHour = res.data.huasazuzhuangWeixiuzichaHour ? res.data.huasazuzhuangWeixiuzichaHour : 0;
        this.huasazuzhuangGuanlixunshenSum = res.data.huasazuzhuangGuanlixunshenSum ? res.data.huasazuzhuangGuanlixunshenSum : 0;
        this.huasazuzhuangGuanlixunshenHour = res.data.huasazuzhuangGuanlixunshenHour ? res.data.huasazuzhuangGuanlixunshenHour : 0;
        this.huasazuzhuangHuopijigaiSum = res.data.huasazuzhuangHuopijigaiSum ? res.data.huasazuzhuangHuopijigaiSum : 0;
        this.huasazuzhuangHuopijigaiHour = res.data.huasazuzhuangHuopijigaiHour ? res.data.huasazuzhuangHuopijigaiHour : 0;

        // QA组装
        this.QAzuozhuangChejianbaoxiuSum = res.data.QAzuozhuangChejianbaoxiuSum ? res.data.QAzuozhuangChejianbaoxiuSum : 0;
        this.QAzuozhuangChejianbaoxiuHour = res.data.QAzuozhuangChejianbaoxiuHour ? res.data.QAzuozhuangChejianbaoxiuHour : 0;
        this.QAzuozhuangWeixiuzichaSum = res.data.QAzuozhuangWeixiuzichaSum ? res.data.QAzuozhuangWeixiuzichaSum : 0;
        this.QAzuozhuangWeixiuzichaHour = res.data.QAzuozhuangWeixiuzichaHour ? res.data.QAzuozhuangWeixiuzichaHour : 0;
        this.QAzuozhuangGuanlixunshenSum = res.data.QAzuozhuangGuanlixunshenSum ? res.data.QAzuozhuangGuanlixunshenSum : 0;
        this.QAzuozhuangGuanlixunshenHour = res.data.QAzuozhuangGuanlixunshenHour ? res.data.QAzuozhuangGuanlixunshenHour : 0;
        this.QAzuozhuangHuopijigaiSum = res.data.QAzuozhuangHuopijigaiSum ? res.data.QAzuozhuangHuopijigaiSum : 0;
        this.QAzuozhuangHuopijigaiHour = res.data.QAzuozhuangHuopijigaiHour ? res.data.QAzuozhuangHuopijigaiHour : 0;

        // 行政楼
        this.xingzhenglouChejianbaoxiuSum = res.data.xingzhenglouChejianbaoxiuSum ? res.data.xingzhenglouChejianbaoxiuSum : 0;
        this.xingzhenglouChejianbaoxiuHour = res.data.xingzhenglouChejianbaoxiuHour ? res.data.xingzhenglouChejianbaoxiuHour : 0;
        this.xingzhenglouWeixiuzichaSum = res.data.xingzhenglouWeixiuzichaSum ? res.data.xingzhenglouWeixiuzichaSum : 0;
        this.xingzhenglouWeixiuzichaHour = res.data.xingzhenglouWeixiuzichaHour ? res.data.xingzhenglouWeixiuzichaHour : 0;
        this.xingzhenglouGuanlixunshenSum = res.data.xingzhenglouGuanlixunshenSum ? res.data.xingzhenglouGuanlixunshenSum : 0;
        this.xingzhenglouGuanlixunshenHour = res.data.xingzhenglouGuanlixunshenHour ? res.data.xingzhenglouGuanlixunshenHour : 0;
        this.xingzhenglouHuopijigaiSum = res.data.xingzhenglouHuopijigaiSum ? res.data.xingzhenglouHuopijigaiSum : 0;
        this.xingzhenglouHuopijigaiHour = res.data.xingzhenglouHuopijigaiHour ? res.data.xingzhenglouHuopijigaiHour : 0;

        // 污水处理
        this.wushuilouChejianbaoxiuSum = res.data.wushuilouChejianbaoxiuSum ? res.data.wushuilouChejianbaoxiuSum : 0;
        this.wushuilouChejianbaoxiuHour = res.data.wushuilouChejianbaoxiuHour ? res.data.wushuilouChejianbaoxiuHour : 0;
        this.wushuilouWeixiuzichaSum = res.data.wushuilouWeixiuzichaSum ? res.data.wushuilouWeixiuzichaSum : 0;
        this.wushuilouWeixiuzichaHour = res.data.wushuilouWeixiuzichaHour ? res.data.wushuilouWeixiuzichaHour : 0;
        this.wushuilouGuanlixunshenSum = res.data.wushuilouGuanlixunshenSum ? res.data.wushuilouGuanlixunshenSum : 0;
        this.wushuilouGuanlixunshenHour = res.data.wushuilouGuanlixunshenHour ? res.data.wushuilouGuanlixunshenHour : 0;
        this.wushuilouHuopijigaiSum = res.data.wushuilouHuopijigaiSum ? res.data.wushuilouHuopijigaiSum : 0;
        this.wushuilouHuopijigaiHour = res.data.wushuilouHuopijigaiHour ? res.data.wushuilouHuopijigaiHour : 0;





        this.yixianSumByDate = res.data.yixianSumByDate ? res.data.yixianSumByDate : 0;
        this.erxianSumByDate = res.data.erxianSumByDate ? res.data.erxianSumByDate : 0;
        this.sanxianSumByDate = res.data.sanxianSumByDate ? res.data.sanxianSumByDate : 0;
        this.zhuzaoSumByDate = res.data.zhuzaoSumByDate ? res.data.zhuzaoSumByDate : 0;
        this.yazhuSumByDate = res.data.yazhuSumByDate ? res.data.yazhuSumByDate : 0;
        this.jijiaSumByDate = res.data.jijiaSumByDate ? res.data.jijiaSumByDate : 0;
        this.gongmoSumByDate = res.data.gongmoSumByDate ? res.data.gongmoSumByDate : 0;
        this.yanmoSumByDate = res.data.yanmoSumByDate ? res.data.yanmoSumByDate : 0;
        this.tuzhuangSumByDate = res.data.tuzhuangSumByDate ? res.data.tuzhuangSumByDate : 0;
        this.QApinbaoSumByDate = res.data.QApinbaoSumByDate ? res.data.QApinbaoSumByDate : 0;
        this.shenghuoSumByDate = res.data.shenghuoSumByDate ? res.data.shenghuoSumByDate : 0;
        this.pvdSumByDate = res.data.pvdSumByDate ? res.data.pvdSumByDate : 0;
        this.zhusuSumByDate = res.data.zhusuSumByDate ? res.data.zhusuSumByDate : 0;
        this.sheshiSumByDate = res.data.sheshiSumByDate ? res.data.sheshiSumByDate : 0;
        this.likuSumByDate = res.data.likuSumByDate ? res.data.likuSumByDate : 0;
        this.zuzhuangSumByDate = res.data.zuzhuangSumByDate ? res.data.zuzhuangSumByDate : 0;
        this.huasaSumByDate = res.data.huasaSumByDate ? res.data.huasaSumByDate : 0;
        this.QAzuzhuangSumByDate = res.data.QAzuzhuangSumByDate ? res.data.QAzuzhuangSumByDate : 0;
        this.xingzhengSumByDate = res.data.xingzhengSumByDate ? res.data.xingzhengSumByDate : 0;
        this.wushuiSumByDate = res.data.wushuiSumByDate ? res.data.wushuiSumByDate : 0;

        this.yixianHourByDate = res.data.yixianHourByDate ? res.data.yixianHourByDate : 0;
        this.erxianHourByDate = res.data.erxianHourByDate ? res.data.erxianHourByDate : 0;
        this.sanxianHourByDate = res.data.sanxianHourByDate ? res.data.sanxianHourByDate : 0;
        this.zhuzaoHourByDate = res.data.zhuzaoHourByDate ? res.data.zhuzaoHourByDate : 0;
        this.yazhuHourByDate = res.data.yazhuHourByDate ? res.data.yazhuHourByDate : 0;
        this.jijiaHourByDate = res.data.jijiaHourByDate ? res.data.jijiaHourByDate : 0;
        this.gongmoHourByDate = res.data.gongmoHourByDate ? res.data.gongmoHourByDate : 0;
        this.yanmoHourByDate = res.data.yanmoHourByDate ? res.data.yanmoHourByDate : 0;
        this.tuzhuangHourByDate = res.data.tuzhuangHourByDate ? res.data.tuzhuangHourByDate : 0;
        this.QApinbaoHourByDate = res.data.QApinbaoHourByDate ? res.data.QApinbaoHourByDate : 0;
        this.shenghuoHourByDate = res.data.shenghuoHourByDate ? res.data.shenghuoHourByDate : 0;
        this.pvdHourByDate = res.data.pvdHourByDate ? res.data.pvdHourByDate : 0;
        this.zhusuHourByDate = res.data.zhusuHourByDate ? res.data.zhusuHourByDate : 0;
        this.sheshiHourByDate = res.data.sheshiHourByDate ? res.data.sheshiHourByDate : 0;
        this.likuHourByDate = res.data.likuHourByDate ? res.data.likuHourByDate : 0;
        this.zuzhuangHourByDate = res.data.zuzhuangHourByDate ? res.data.zuzhuangHourByDate : 0;
        this.huasaHourByDate = res.data.huasaHourByDate ? res.data.huasaHourByDate : 0;
        this.QAzuzhuangHourByDate = res.data.QAzuzhuangHourByDate ? res.data.QAzuzhuangHourByDate : 0;
        this.xingzhengHourByDate = res.data.xingzhengHourByDate ? res.data.xingzhengHourByDate : 0;
        this.wushuiHourByDate = res.data.wushuiHourByDate ? res.data.wushuiHourByDate : 0;

        /**
         * 汇总各车间八大项
         */



        this.initChartRecord();
        this.initChatHour();
      })
    },
    load() {

      //不论时间
      this.request.get("/failureRecord/getLineRecordSum").then(res => {
        this.yixianSum = res.data.yixianSum ? res.data.yixianSum : 0
        this.erxianSum = res.data.erxianSum ? res.data.erxianSum : 0
        this.sanxianSum = res.data.sanxianSum ? res.data.sanxianSum : 0
        this.zhuzaoSum = res.data.zhuzaoSum ? res.data.zhuzaoSum : 0
        this.yazhuSum = res.data.yazhuSum ? res.data.yazhuSum : 0
        this.jijiaSum = res.data.jijiaSum ? res.data.jijiaSum : 0
        this.gongmoSum = res.data.gongmoSum ? res.data.gongmoSum : 0
        this.yanmoSum = res.data.yanmoSum ? res.data.yanmoSum : 0
        this.tuzhuangSum = res.data.tuzhuangSum ? res.data.tuzhuangSum : 0
        this.QApinbaoSum = res.data.QApinbaoSum ? res.data.QApinbaoSum : 0
        this.shenghuoSum = res.data.shenghuoSum ? res.data.shenghuoSum : 0
        this.pvdSum = res.data.pvdSum ? res.data.pvdSum : 0
        this.zhusuSum = res.data.zhusuSum ? res.data.zhusuSum : 0
        this.sheshiSum = res.data.sheshiSum ? res.data.sheshiSum : 0
        this.likuSum = res.data.likuSum ? res.data.likuSum : 0
        this.zuzhuangSum = res.data.zuzhuangSum ? res.data.zuzhuangSum : 0
        this.huasaSum = res.data.huasaSum ? res.data.huasaSum : 0
        this.QAzuzhuangSum = res.data.QAzuzhuangSum ? res.data.QAzuzhuangSum : 0
        this.xingzhengSum = res.data.xingzhengSum ? res.data.xingzhengSum : 0
        this.wushuiSum = res.data.wushuiSum ? res.data.wushuiSum : 0

        this.yixianHour = res.data.yixianHour ? res.data.yixianHour : 0
        this.erxianHour = res.data.erxianHour ? res.data.erxianHour : 0
        this.sanxianHour = res.data.sanxianHour ? res.data.sanxianHour : 0
        this.zhuzaoHour = res.data.zhuzaoHour ? res.data.zhuzaoHour : 0
        this.yazhuHour = res.data.yazhuHour ? res.data.yazhuHour : 0
        this.jijiaHour = res.data.jijiaHour ? res.data.jijiaHour : 0
        this.gongmoHour = res.data.gongmoHour ? res.data.gongmoHour : 0
        this.yanmoHour = res.data.yanmoHour ? res.data.yanmoHour : 0
        this.tuzhuangHour = res.data.tuzhuangHour ? res.data.tuzhuangHour : 0
        this.QApinbaoHour = res.data.QApinbaoHour ? res.data.QApinbaoHour : 0
        this.shenghuoHour = res.data.shenghuoHour ? res.data.shenghuoHour : 0
        this.pvdHour = res.data.pvdHour ? res.data.pvdHour : 0
        this.zhusuHour = res.data.zhusuHour ? res.data.zhusuHour : 0
        this.sheshiHour = res.data.sheshiHour ? res.data.sheshiHour : 0
        this.likuHour = res.data.likuHour ? res.data.likuHour : 0
        this.zuzhuangHour = res.data.zuzhuangHour ? res.data.zuzhuangHour : 0
        this.huasaHour = res.data.huasaHour ? res.data.huasaHour : 0
        this.QAzuzhuangHour = res.data.QAzuzhuangHour ? res.data.QAzuzhuangHour : 0
        this.xingzhengHour = res.data.xingzhengHour ? res.data.xingzhengHour : 0
        this.wushuiHour = res.data.wushuiHour ? res.data.wushuiHour : 0
        this.initChart();
        this.initChart2();
        this.initChartRecord();

        // this.initChart3();
        // this.initChart4();
        // this.initChart5();
        // this.initChart6();
        // this.initChart7();
        // this.initChart8();
        // this.initChart9();
        // this.initChart10();
        // this.initChart11();
        // this.initChart12();
        // this.initChart13();
        // this.initChart14();
        // this.initChart15();
        // this.initChart16();
        // this.initChart17();
        // this.initChart18();
        // this.initChart19();
        // this.initChart20();
        // this.initChart21();
        // this.initChart22();
        // this.initChart23();
        // this.initChart24();
        // this.initChart25();
        // this.initChart26();
        // this.initChart27();
        // this.initChart28();
        // this.initChart29();
        // this.initChart30();
        // this.initChart31();
        // this.initChart32();
        // this.initChart33();
        // this.initChart34();
        // this.initChart35();
        // this.initChart36();
        // this.initChart37();
        // this.initChart38();
        // this.initChart39();
        // this.initChart40();
        // this.initChart41();
        // this.initChart42();

      })

      this.request.get("/line").then(res => {
        this.lines = res.data;
      })

    },
    initChartRecord() {

      var app = {};

      var chartDom = document.getElementById('chatRecord');
      var myChart = echarts.init(chartDom);
      var option;

      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };
      option = {
        title: {
          text: '车间工单柱状图',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['车间报修', '维修自查', '管理巡审', '获批技改']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '电镀一线',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yixianchejianbaoxiuSum, this.yixianweixiuzichaSum, this.yixianGuanlixunshenSum, this.yixianHuopijigaiSum]
          },
          {
            name: '电镀二线',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.erxianchejianbaoxiuSum, this.erxianweixiuzichaSum, this.erxianGuanlixunshenSum, this.erxianHuopijigaiSum]
          },
          {
            name: '电镀三线',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.sanxianchejianbaoxiuSum, this.sanxianweixiuzichaSum, this.sanxianGuanlixunshenSum,this.sanxianHuopijigaiSum]
          },
          {
            name: '铸造',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zhuzaochejianbaoxiuSum, this.zhuzaoweixiuzichaSum, this.zhuzaoGuanlixunshenSum,this.zhuzaoHuopijigaiSum]
          },
          {
            name: '压铸',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yazhuchejianbaoxiuSum, this.yazhuweixiuzichaSum, this.yazhuGuanlixunshenSum, this.yazhuHuopijigaiSum]
          },
          {
            name: '机加',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.jijiachejianbaoxiuSum, this.jijiaweixiuzichaSum, this.jijiaGuanlixunshenSum, this.jijiaHuopijigaiSum]
          },
          {
            name: '工模',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.gongmochejianbaoxiuSum, this.gongmoweixiuzichaSum, this.gongmoGuanlixunshenSum, this.gongmoHuopijigaiSum]
          },
          {
            name: '研磨',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yanmochejianbaoxiuSum, this.yanmoweixiuzichaSum, this.yanmoGuanlixunshenSum, this.yanmoHuopijigaiSum]
          },
          {
            name: '涂装',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.tuzhuangchejianbaoxiuSum, this.tuzhuangweixiuzichaSum, this.tuzhuangGuanlixunshenSum, this.tuzhuangHuopijigaiSum]
          },
          {
            name: 'QA品保',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.QAPinbaoChejianbaoxiuSum, this.QAPinbaoWeixiuzichaSum, this.QAPinbaoGuanlixunshenSum, this.QAPinbaoHuopijigaiSum]
          },
          {
            name: '生活污水',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.shenghuowushuiChejianbaoxiuSum, this.shenghuowushuiWeixiuzichaSum,this.shenghuowushuiGuanlixunshenSum, this.shenghuowushuiHuopijigaiSum]
          },
          {
            name: 'PVD',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.PVDchejianbaoxiuSum, this.PVDweixiuzichaSum, this.PVDGuanlixunshenSum, this.PVDHuopijigaiSum]
          },
          {
            name: '注塑',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zhusuchejianbaoxiuSum, this.zhusuweixiuzichaSum, this.zhusuGuanlixunshenSum, this.zhusuHuopijigaiSum]
          },
          {
            name: '设施',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.sheshichejianbaoxiuSum, this.sheshiweixiuzichaSum, this.sheshiGuanlixunshenSum,this.sheshiHuopijigaiSum]
          },
          {
            name: '立库',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.likuChejianbaoxiuSum, this.likuWeixiuzichaSum, this.likuGuanlixunshenSum, this.likuHuopijigaiSum]
          },
          {
            name: '组装',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zuzhuangchejianbaoxiuSum, this.zuzhuangweixiuzichaSum, this.zuzhuangGuanlixunshenSum, this.zuzhuangHuopijigaiSum]
          },
          {
            name: '花洒组装',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.huasazuzhuangChejianbaoxiuSum, this.huasazuzhuangWeixiuzichaSum, this.huasazuzhuangGuanlixunshenSum, this.huasazuzhuangHuopijigaiSum]
          },
          {
            name: 'QA组装',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.QAzuozhuangChejianbaoxiuSum, this.QAzuozhuangWeixiuzichaSum, this.QAzuozhuangGuanlixunshenSum, this.QAzuozhuangHuopijigaiSum]
          },
          {
            name: '行政楼',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.xingzhenglouChejianbaoxiuSum, this.xingzhenglouWeixiuzichaSum, this.xingzhenglouGuanlixunshenSum, this.xingzhenglouHuopijigaiSum]
          },
          {
            name: '污水处理',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.wushuilouChejianbaoxiuSum, this.wushuilouWeixiuzichaSum, this.wushuilouGuanlixunshenSum, this.wushuilouHuopijigaiSum]
          },
        ]
      };

      option && myChart.setOption(option);

    },
    initChatHour() {

      var app = {};

      var chartDom = document.getElementById('chatHour');
      var myChart = echarts.init(chartDom);
      var option;

      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };
      option = {
        title: {
          text: '车间工时柱状图',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['车间报修', '维修自查', '管理巡审', '获批技改']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '电镀一线',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yixianchejianbaoxiuHour, this.yixianweixiuzichaHour, this.yixianGuanlixunshenHour, this.yixianHuopijigaiHour]
          },
          {
            name: '电镀二线',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.erxianchejianbaoxiuHour, this.erxianweixiuzichaHour, this.erxianGuanlixunshenHour, this.erxianHuopijigaiHour]
          },
          {
            name: '电镀三线',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.sanxianchejianbaoxiuHour, this.sanxianweixiuzichaHour, this.sanxianGuanlixunshenHour, this.sanxianHuopijigaiHour]
          },
          {
            name: '铸造',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zhuzaochejianbaoxiuHour, this.zhuzaoweixiuzichaHour, this.zhuzaoGuanlixunshenHour, this.zhuzaoHuopijigaiHour]
          },
          {
            name: '压铸',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yazhuchejianbaoxiuHour, this.yazhuweixiuzichaHour, this.yazhuGuanlixunshenHour, this.yazhuHuopijigaiHour]
          },
          {
            name: '机加',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.jijiachejianbaoxiuHour, this.jijiaweixiuzichaHour, this.jijiaGuanlixunshenHour, this.jijiaHuopijigaiHour]
          },
          {
            name: '工模',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.gongmochejianbaoxiuHour, this.gongmoweixiuzichaHour, this.gongmoGuanlixunshenHour, this.gongmoHuopijigaiHour]
          },
          {
            name: '研磨',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.yanmochejianbaoxiuHour, this.yanmoweixiuzichaHour, this.yanmoGuanlixunshenHour, this.yanmoHuopijigaiHour]
          },
          {
            name: '涂装',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.tuzhuangchejianbaoxiuHour, this.tuzhuangweixiuzichaHour, this.tuzhuangGuanlixunshenHour, this.tuzhuangHuopijigaiHour]
          },
          {
            name: 'QA品保',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.QAPinbaoChejianbaoxiuHour, this.QAPinbaoWeixiuzichaHour, this.QAPinbaoGuanlixunshenHour, this.QAPinbaoHuopijigaiHour]
          },
          {
            name: '生活污水',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.shenghuowushuiChejianbaoxiuHour, this.shenghuowushuiWeixiuzichaHour, this.shenghuowushuiGuanlixunshenHour, this.shenghuowushuiHuopijigaiHour]
          },
          {
            name: 'PVD',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.PVDchejianbaoxiuHour, this.PVDweixiuzichaHour, this.PVDGuanlixunshenHour, this.PVDHuopijigaiHour]
          },
          {
            name: '注塑',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zhusuchejianbaoxiuHour, this.zhusuweixiuzichaHour, this.zhusuGuanlixunshenHour, this.zhusuHuopijigaiHour]
          },
          {
            name: '设施',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.sheshichejianbaoxiuHour, this.sheshiweixiuzichaHour, this.sheshiGuanlixunshenHour, this.sheshiHuopijigaiHour]
          },
          {
            name: '立库',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.likuChejianbaoxiuHour, this.likuWeixiuzichaHour, this.likuGuanlixunshenHour, this.likuHuopijigaiHour]
          },
          {
            name: '组装',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.zuzhuangchejianbaoxiuHour, this.zuzhuangweixiuzichaHour, this.zuzhuangGuanlixunshenHour, this.zuzhuangHuopijigaiHour]
          },
          {
            name: '花洒组装',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.huasazuzhuangChejianbaoxiuHour, this.huasazuzhuangWeixiuzichaHour, this.huasazuzhuangGuanlixunshenHour, this.huasazuzhuangHuopijigaiHour]
          },
          {
            name: 'QA组装',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.QAzuozhuangChejianbaoxiuHour, this.QAzuozhuangWeixiuzichaHour, this.QAzuozhuangGuanlixunshenHour, this.QAzuozhuangHuopijigaiHour]
          },
          {
            name: '行政楼',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.xingzhenglouChejianbaoxiuHour, this.xingzhenglouWeixiuzichaHour, this.xingzhenglouGuanlixunshenHour, this.xingzhenglouHuopijigaiHour]
          },
          {
            name: '污水处理',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [this.wushuilouChejianbaoxiuHour, this.wushuilouWeixiuzichaHour, this.wushuilouGuanlixunshenHour, this.wushuilouHuopijigaiHour]
          },
        ]

      };

      option && myChart.setOption(option);

    },
    initChart() {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: '车间工单饼图',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: 'right',
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '50%',
            data: [
              { value: this.yixianSum, name: '电镀一线' },
              { value: this.erxianSum, name: '电镀二线' },
              { value: this.sanxianSum, name: '电镀三线' },
              { value: this.zhuzaoSum, name: '铸造' },
              { value: this.yazhuSum, name: '压铸' },
              { value: this.jijiaSum, name: '机加' },
              { value: this.gongmoSum, name: '工模' },
              { value: this.yanmoSum, name: '研磨' },
              { value: this.tuzhuangSum, name: '涂装' },
              { value: this.QApinbaoSum, name: 'QA品保' },
              { value: this.shenghuoSum, name: '生活污水' },
              { value: this.pvdSum, name: 'PVD' },
              { value: this.zhusuSum, name: '注塑' },
              { value: this.sheshiSum, name: '设施维修' },
              { value: this.likuSum, name: '立库' },
              { value: this.zuzhuangSum, name: '组装' },
              { value: this.huasaSum, name: '花洒组装' },
              { value: this.QAzuzhuangSum, name: 'QA组装' },
              { value: this.xingzhengSum, name: '行政楼' },
              { value: this.wushuiSum, name: '污水处理' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
    initChart2() {
      const chartDom = this.$refs.chart2;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: '车间工时饼图',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: 'right',
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '50%',
            data: [
              { value: this.yixianHour, name: '电镀一线' },
              { value: this.erxianHour, name: '电镀二线' },
              { value: this.sanxianHour, name: '电镀三线' },
              { value: this.zhuzaoHour, name: '铸造' },
              { value: this.yazhuHour, name: '压铸' },
              { value: this.jijiaHour, name: '机加' },
              { value: this.gongmoHour, name: '工模' },
              { value: this.yanmoHour, name: '研磨' },
              { value: this.tuzhuangHour, name: '涂装' },
              { value: this.QApinbaoHour, name: 'QA品保' },
              { value: this.shenghuoHour, name: '生活污水' },
              { value: this.pvdHour, name: 'PVD' },
              { value: this.zhusuHour, name: '注塑' },
              { value: this.sheshiHour, name: '设施维修' },
              { value: this.likuHour, name: '立库' },
              { value: this.zuzhuangHour, name: '组装' },
              { value: this.huasaHour, name: '花洒组装' },
              { value: this.QAzuzhuangHour, name: 'QA组装' },
              { value: this.xingzhengHour, name: '行政楼' },
              { value: this.wushuiHour, name: '污水处理' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },



    handleChange(val) {
      console.log(val);
    },
  }
}
</script>
<style scoped>
.tree-chart-container {
  width: 100%;
  height: 1000px; /* 确保有足够的高度 */
}
.chart-container {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: space-between; /* 控制间距 */
}

.chart {
  width: 500px; /* 每个图表的宽度 */
  height: 500px; /* 每个图表的高度 */
}

.chart2 {
  width: 400px; /* 每个图表的宽度 */
  height: 200px; /* 每个图表的高度 */
}
.block{
  width: 200px; /* 每个图表的宽度 */
  height: 200px; /* 每个图表的高度 */
}
.chat3{
  width: 1200px; /* 每个图表的宽度 */
  height: 800px; /* 每个图表的高度 */
}
</style>